import Container from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const StyledPrivacyPolicy = styled.div`
  ${Container} {
    padding: 30px 60px 90px 60px;
  }

  .links-routes {
    margin-bottom: 30px;
    h4 {
      padding: 0px;
      margin: 0px;
      color: ${({theme}) => theme.colors.secondary2};
    }
  }

  .add-products-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .price-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 10px;
    h3 {
      margin-right: 20px;
    }
  }

  .trending-wrapper {
    border: 1.5px solid ${({theme}) => theme.colors.primary2};
    border-radius: 28px;
    padding: 6px;
    width: 150px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 30px;
  }

  .head-info {
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
    padding: 18px 0px 18px;
    margin-bottom: 25px;
  }

  .list-info {
    display: flex;
    flex-flow: column wrap;
    margin: 20px 0px;
    height: 60px;
    li {
      font-size: ${({theme}) => theme.typography.fontSizes[4]};
      line-height: 18px;
    }
  }

  .you-might-also-like {
    margin-top: 60px;
    h3 {
      margin-bottom: 30px;
    }
  }

  .slider-collection {
    margin-top: 30px;
    h3 {
      margin-bottom: 30px;
    }
  }
  .container-row {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    ol {
      margin: 30px 55px;
    }
    .quantity-picker {
      display: flex;
      margin-right: 10px;
      border: 1.5px solid ${({theme}) => theme.colors.secondary2};
      margin-top: 8px;
      margin-bottom: 30px;
      border-radius: 6px;
      height: 42px;
      width: 17.8rem;
      align-items: center;
      justify-content: space-between;
    }
    .quantity-modifier {
      font-size: 1rem;
      font-weight: bold;
    }
    .quantity-display {
      background-color: ${({theme}) => theme.colors.secondary3};
      font-size: 16px;
    }
    > button {
      margin-top: 30px;
    }

    h4 {
      text-transform: uppercase;
    }
    
  }
  h1 {
    margin-bottom: 20px;
    margin-top: 6px;
  }
`;
