import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const LetsBePartnersInfoStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  .contact-info {
    margin-left: 75px;
    padding-top: 90px;
    margin-bottom: 60px;

    h1 {
      color: ${({theme}) => theme.colors.primary};
      font-weight: 600;
      margin-bottom: 20px;
    }
    h2 {
      color: ${({theme}) => theme.colors.primary};
      font-weight: 600;
      margin-top: 40px;
      margin-bottom: 18px;
      cursor: pointer;
    }
    h3 {
      width: 90%;
    }

    h4 {
      width: 100%;
    }
    h6 {
      cursor: pointer;
    }
    .social {
      height: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      h6 {
        cursor: pointer;
      }
      .networks {
        height: 23px;
        width: 40%;
        display: flex;
        align-items: center;
        flex-direction: row;

        img {
          margin-right: 8px;
        }
      }

      h5 {
        width: 100%;
        &:hover {
          cursor: pointer;
          color: ${({theme}) => theme.colors.primary3};
        }
      }
    }
  }
`;

export const ContactFormStyle = styled.div`
  padding: 7px 25px;
  max-height: 674px;
  width: 86%;
  border-radius: 20px;
  margin-top: 55px;
  margin-bottom: 60px;
  background: ${({theme}) => theme.colors.bgColor};

  h2 {
    color: ${({theme}) => theme.colors.primary2};
    font-weight: 600;
    margin-top: 12px;
  }

  h3 {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  h4 {
    color: ${({theme}) => theme.colors.secondary};
    font-weight: 500;
    margin-bottom: 20px;
    width: 79%;
    margin-right: 30px;
  }

  .field {
    display: flex;
    justify-content: space-between;
    .wrapper-text {
      width: 100%;
    }
  }

  .form-footer {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    a { 
      color: ${({theme}) => theme.colors.primary2};
      &:hover {
        cursor: pointer;
        color: ${({theme}) => theme.colors.primary3};
      }
      &:active {
        color: ${({theme}) => theme.colors.primary2};
      }
   }
  }

  span {
    margin-left: 5px;
    list-style: none;
    font-weight: 500;
    line-height: 23px;
    font-size: ${({theme}) => theme.typography.fontSizes[6]};
    color: ${({theme}) => theme.colors.primary2};

    &:hover {
      cursor: pointer;
      color: ${({theme}) => theme.colors.primary3};
    }
    &:active {
      color: ${({theme}) => theme.colors.primary2};
    }
  }
`;

export const WeAreHereStyle = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  margin-bottom: 90px;
  justify-content: center;
  align-items: center;

  .weAreHere {
    padding-left: 20px;
    height: 395px;
    width: 70%;
    border-radius: 20px;
    background: ${({theme}) => theme.colors.bgColor};
    ${props => config(props).media['md']`
    width: 87%;
  `}

    button {
      width: 154px;
      margin-top: 15px;
      margin-bottom: 28px;
      p {
        color: white;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.1em;
      }
    }

    hr {
      width: 93%;
      border-style: solid;
      border-color: ${({theme}) => theme.colors.secondary2};
    }

    h1 {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    h2 {
      margin-top: 28px;
      margin-bottom: 15px;
      font-weight: 600;
    }

    h4 {
      font-weight: 600;
    }
  }
  .map {
    display: flex;
    align-items: center;
    img {
      margin-top: 90px;
      display: flex;
      width: 100%;
      border-radius: 20px;
      justify-content: center;
      ${props => config(props).media['md']`
    margin-top:0px;

    `}
    }
  }
`;

export const InstaGalleryStyle = styled.div`
  width: 99%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 90px;

  h1 {
    color: ${({theme}) => theme.colors.primary2};
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 600;
    padding-bottom: 50px;
  }
  .instaImg {
    display: flex;
    justify-content: center;
  }
  .instaImg img {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 15px;
    margin-left: 5px;
  }
`;
