import React from 'react';
import PropTypes from 'prop-types';
import {Text} from 'styled-typography';
import InputForm from '../core/InputForm';
import {ContactFormStyle} from './styles';
import ButtonBlack from '../../components/core/ButtonBlack';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

const Form = ({contact, onInputChange, errors, onRegister}) => {
  const {nameContact, companyOrStore, emailContact, phoneNumber, subject} =
    contact;

  function onChangeCaptcha(value) { 
    'Captcha value:', value;
  }

  return (
    <ContactFormStyle>
      <Text className="title1-normal" as="h2">
        How Can We Help You?
      </Text>
      <Text level="5" as="h3">
        For any customer service inquiries, please fill out the form below and
        we will get back to you by the following business day. For any urgent
        cases, please call us at 305-576-1195 from Monday to Friday 9 am to 5 pm
        EST.
      </Text>
      <form>
        <div className="field">
          <div>
            <Text level="6">Name*</Text>
            <InputForm
              type="text"
              field="nameContact"
              placeholder="Name"
              value={nameContact}
              errors={errors}
              onChange={onInputChange('nameContact')}
            />
          </div>
          <div>
            <Text level="6">Company or Store Name*</Text>
            <InputForm
              type="text"
              field="companyOrStore"
              placeholder="Company or Store Name"
              value={companyOrStore}
              errors={errors}
              onChange={onInputChange('companyOrStore')}
            />
          </div>
        </div>

        <div className="field">
          <div>
            <Text level="6">Email Address*</Text>
            <InputForm
              type="email"
              errors={errors}
              value={emailContact}
              placeholder="example@makers.com"
              field="emailContact"
              onChange={onInputChange('emailContact')}
            />
          </div>
          <div>
            <Text level="6">Phone number*</Text>
            <InputForm
              type="number"
              field="phoneNumber"
              placeholder="+1"
              value={phoneNumber}
              errors={errors}
              onChange={onInputChange('phoneNumber')}
            />
          </div>
        </div>

        <div className="field">
          <div className="wrapper-text">
            <Text level="6">Your Subject*</Text>
            <InputForm
              type="text"
              field="subject"
              placeholder="Your Subject"
              value={subject}
              errors={errors}
              onChange={onInputChange('subject')}
            />
          </div>
        </div>
      </form>

      <GoogleReCaptcha />
      <div id="container-captcha" className="container-captcha">
      </div>
      <div style={{marginTop: '20px'}}>
        <ButtonBlack onClick={onRegister}>SEND NOW</ButtonBlack>
      </div>
      <Text level="6" className="form-footer">
        <p>
        consenting to be contacted by SMS text message from Maker's Shoes.
        Message frequency may vary. Message & data rates may apply. Reply
        STOP to opt-out of further messaging. Reply HELP for more
        information. See our <a href="/privacy-policy" target="_blank">Privacy Policy</a>
        </p>
      </Text>
    </ContactFormStyle>
  );
};

export default Form;
Form.propTypes = {
  onRegister: PropTypes.func,
  onInputChange: PropTypes.func,
  contact: PropTypes.shape({
    nameContact: PropTypes.string,
    companyOrStore: PropTypes.string,
    emailContact: PropTypes.string,
    phoneNumber: PropTypes.number,
    subject: PropTypes.string,
  }),
  errors: PropTypes.array,
};
